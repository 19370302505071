import React from 'react';
import Seo from '../components/Seo';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import ComparisonProductGrid from '../components/ComparisonProductGrid';
import {ReactSearchAutocomplete} from 'react-search-autocomplete'

export default ({pageContext, location}) => {
    const {seo = {}, hideFromSearchEngine, results = []} = pageContext;
    const metaTitle = seo.metaTitle || `Compare Category ${pageContext.title} - Genexa`;
    const openGraphTitle = seo.openGraphTitle || `Compare Category ${pageContext.title} - Genexa`;
    const twitterTitle = seo.twitterTitle || `Compare Category ${pageContext.title} - Genexa`;
    var locals = [];
    var filter = (obj) => {
        var r;
        var n;
        return n = obj.name, r = RegExp("" + obj.brand, "ig"), n.replace(r, "<strong class='brand-name'>$&</strong>");
    };
    results.forEach((obj, i) => {
        var newobj = {url: obj.url, name: obj.name, brand: obj.brand}
        // var newobj={url:obj.url,name:obj.name,brand:obj.brand,description:<p dangerouslySetInnerHTML={{__html: filter(obj)}}></p>}
        locals[i] = newobj;
    });

    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        //console.log(string, results)
    }

    const handleOnHover = (result) => {
        // the item hovered
        //console.log(result)
    }

    const handleOnSelect = (item) => {
        //console.log(this);
        // the item selected
        //console.log('selected');
        //console.log(item)
        window.location.href = item.url;
    }

    const handleOnFocus = () => {
        //console.log('Focused')
    }

    return (
        <React.Fragment>
            <Seo
                metaTitle={metaTitle}
                metaDescription={seo.metaDescription}
                metaKeywords={seo.metaKeywords}
                openGraphTitle={openGraphTitle}
                openGraphDescription={seo.openGraphDescription}
                openGraphImage={seo.openGraphImage}
                twitterTitle={twitterTitle}
                twitterDescription={seo.twitterDescription}
                twitterImage={seo.twitterImage}
                pathname={location.pathname}
                noIndexNoFollow={hideFromSearchEngine}
            />

            <            div class="section-content">
                <h1 className="brand-name">Compare Hundreds Of Popular Medicines</h1>
                <ReactSearchAutocomplete
                    items={locals}
                    onSearch={handleOnSearch}
                    onHover={handleOnHover}
                    onSelect={handleOnSelect}
                    onFocus={handleOnFocus}
                    autoFocus={true}
                    showIcon={false}
                    showClear={false}
                    resultStringKeyName="name"
                    maxResults={10}
                    fuseOptions={{
                        threshold: 0.6,
                        isCaseSensitive: false,
                        shouldSort: true,
                        includeScore: false,
                    }}
                    placeholder="Search Hundreds of Medicines..."

                />
            </div>
            <div class="compare-container-brand">
                {pageContext.warning && (
                    <div class="warning">
                        <div class="warning-inner">
                            <div className="tc rich-text line-break">
                                <BlockContent
                                    blocks={pageContext.warning}
                                    serializers={richText}
                                />
                            </div>
                        </div>
                    </div>
                )}
                <div className='comparehead'>
                    <h1>{pageContext.title}</h1>
                </div>
                <ComparisonProductGrid
                    {...pageContext}
                />

            </div>
        </React.Fragment>
    );
};
